<template>
  <div id="page-user-view" class="px-4">
    <h2 class="mb-5">{{$t('ShipBlu Shield')}}</h2>
    <shipblu-card class="p-5 mt-1" :cardLoading="merchantInsuranceLoader" v-if="merchantInsuranceLoader || (!merchantInsuranceLoader && merchantInsurance.insurance_package && merchantInsurance.insurance_package.id && merchantInsurance.insurance_package.id !== 1)">
      <div class="vx-row">
        <div class="vx-col w-full mb-base">
          <h2 class="title mb-6">{{$t('Your Active Insurance Plan')}}</h2>
          <div class="md:flex justify-between items-center p-5 grid grid-cols-1 gap-4 border-blue-100 active-insurance w-full">
            <div>
              <p class="font-semibold text-xl">{{merchantInsurance.insurance_package.insurance_fees}}%</p>
              <p class="font-normal text-zm">{{$t('Insurance Fees / Order')}}</p>
            </div>
            <div>
              <p class="font-semibold text-xl">{{merchantInsurance.insurance_package.payout_coverage}}%</p>
              <p class="font-normal text-sm">{{$t('Payout Coverage')}}</p>
            </div>
            <div>
              <p class="font-semibold text-xl">{{merchant.default_order_declared_value}} {{$t('EGP')}}</p>
              <p class="font-normal text-sm">{{$t('Your default declared value')}}</p>
            </div>
            <button v-if="merchantStatus === 'active'" class="border-0 bg-transparent text-base font-medium text-red cursor-pointer" @click="showDisableModal = true">{{$t('Disable')}}</button>
          </div>
        </div>
      </div>
    </shipblu-card>
    <shipblu-card :cardLoading="insurancePackages.length === 0" class="mt-4 p-5">
      <div class="flex w-full justify-between items-center">
        <h2 class="title mb-6">{{$t('Insurance Plans')}}</h2>
        <template v-if="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant' && merchantStatus === 'active'">
          <vx-tooltip  position="top" color="warning" :text="`${$t('Your merchant profile is incomplete or still under review. You can still drop off your packages today!')}`">
            <vs-button class="font-medium text-sm cursor-pointer md:block hidden" disabled="true">{{$t('Submit Plan')}}</vs-button>
          </vx-tooltip>
        </template>
        <template v-else>
          <vs-button v-if="merchantStatus === 'active'" class="font-medium text-sm cursor-pointer md:block hidden" :class="insurancePackage.id ? 'active-btn' : 'disabled-btn'" @click="openModal()" :disabled="insurancePackage.id  ? false : true">{{$t('Submit Plan')}}</vs-button>
        </template>
      </div>
      <div>
        <p class="font-semibold leading-8 text-base mr-2 mb-5">{{$t('ShipBlu Ensures The Safe Transport Of Your Goods And Its Responsibilty For Lossess Or Damages Due To Negligence.')}}</p>
        <ul class="w-full list-type ml-8">
          <li class="mb-3">
            <span class="font-semibold leading-5 text-base mr-2 text-blue-900" >{{$t('Insurance Fees:')}}</span>
            <span class="text-sm leading-4 font-normal">{{$t('The Insurance service fees per order')}}</span>
          </li>
          <li class="mb-3">
            <span class="font-semibold leading-5 text-base mr-2  text-blue-900" >{{$t('Payout Coverage %:')}}</span>
            <span class="text-sm leading-4 font-normal">{{$t('The Compensation amount in case of any loss or damage')}}</span>
          </li>
          <li class="mb-6">
            <span class="font-semibold leading-5 text-base mr-2  text-blue-900" >{{$t('Your default declared value:')}}</span>
            <span class="text-sm leading-4 font-normal">{{$t('Incase you have created an order with no cod or declared value,we will apply the default declared value')}}</span>
          </li>
        </ul>
      </div>
      <p class="text-base text-blue-100 mb-5 font-semibold ">{{$t('Please Choose One Of The Below Insurance Plans')}}</p>
      <div class="grid lg:grid-cols-3 grid-cols-1 justify-items-center gap-4">
        <div :class="activeID === insurance.id && errors.first('value') ? 'error-box border-red' : activeID === insurance.id ? 'border-blue-100' : ''" class="insurance-card w-full h-full" v-for="(insurance, index) in insurancePackages" :key="index" >
          <label :for="`${index}`" class="cursor-pointer">
            <div class="flex justify-between">
              <div class=" ml-4 mt-3">
                <p class="mb-3 text-darkblue"><span class="font-semibold">{{insurance.name}}</span> {{$t('Shield')}}</p>
                <p class="text-blue-100 text-base font-semibold">{{insurance.insurance_fees}}%</p>
              </div>
              <div class="mt-4 mr-5">
                <div v-if="activeID === insurance.id && errors.first('value')" class="fake-radiobtn w-5 h-5">
                  <div></div>
                </div>
                <div v-show="!(activeID === insurance.id && errors.first('value'))">
                  <input :id="`${index}`" v-validate="'required'" name="insurance-package" class="w-5 h-5 cursor-pointer" type="radio" v-model="insurancePackage" :class="insurance.checked ? 'checked' : ''" :value="insurance" />
                </div>
              </div>
            </div>
            <p class="ml-4 mb-2">{{$t('Insurance Fees Per Order')}}</p>
            <h2 class="ml-4 font-semibold">{{insurance.payout_coverage}}%</h2>
            <div class="flex mb-6">
              <p class="ml-4 font-semibold text-xs">{{$t('Payout Coverage For Order Up To')}}</p>
              <p class="ml-1 text-blue-100 font-bold text-xs">{{ Math.abs(insurance.value_limit) > 999 ? Math.sign(insurance.value_limit) * ((Math.abs(insurance.value_limit)/1000).toFixed(1)) + ',000' : Math.sign(insurance.value_limit) * Math.abs(insurance.value_limit) + $t(' EGP') }}</p>
            </div>
            <div class= "mb-4 ml-4" v-show="activeID === insurance.id">
              <div class="flex justify-between items-end">
                <div>
                  <p class="text-xs font-medium ">{{$t('If COD For Order = 0.00 EGP')}}</p>
                  <p class="text-blue-100 text-xs font-semibold mt-1">{{$t('Your default declared value:')}}</p>
                </div>
                <input :class="activeID === insurance.id && errors.first('value') ? 'border-red' : activeID === insurance.id ? 'border-blue-100' : 'border-0'" v-model="value" class="rounded-lg mr-5 ml-5 w-16 h-10 text-center" type="text" name="value" :placeholder="$t('0.00')" v-validate="'required|numeric'" />
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="flex mt-4">
        <div class="relative w-5 h-5">
          <input v-model="insurancePackage.termsCheck" class="w-5 h-5" :class="errors.first('terms check') ? 'require-checked' : ''" type="checkbox" name="terms check" :checked="isSelected" v-validate="'required'"/>
        </div>
        <div class="flex ml-2">
          <p class="font-semibold text-sm">{{$t('I Agree With The')}}</p>
          <a :href="$i18n.locale === 'en' ? 'https://shipblu.freshdesk.com/en/support/solutions/articles/80001052975-shipblu-shield-terms-conditions' : 'https://shipblu.freshdesk.com/ar/support/solutions/articles/80001052975-%D8%A8%D8%A7%D9%82%D8%A7%D8%AA-%D8%AD%D9%85%D8%A7%D9%8A%D8%A9-%D8%B4%D8%A8-%D8%A8%D9%84%D9%88-%D8%A7%D9%84%D8%B4%D8%B1%D9%88%D8%B7-%D9%88%D8%A7%D9%84%D8%A3%D8%AD%D9%83%D8%A7%D9%85'" target="_blank"><p :class="errors.first('terms check') ? 'text-danger' : 'text-blue-100'" class="ml-1 text-sm font-semibold">{{$t('Terms and Conditions')}}</p></a>
        </div>  
      </div>
      <template v-if="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant'">
        <vx-tooltip  position="top" color="warning" :text="`${$t('Your merchant profile is incomplete or still under review. You can still drop off your packages today!')}`">
          <vs-button class="font-medium text-sm cursor-pointer md:hidden my-5 w-full" disabled="true">{{$t('Submit Plan')}}</vs-button>
        </vx-tooltip>
      </template>
      <template v-else>
        <vs-button class="font-medium text-sm cursor-pointer md:hidden my-5 w-full" :class="insurancePackage.id ? 'active-btn' : 'disabled-btn'" @click="openModal()" :disabled="insurancePackage.id  ? false : true">{{$t('Submit Plan')}}</vs-button>
      </template>
    </shipblu-card>
    <shipblu-card :cardLoading="merchantInsuranceHistoryLoader" v-if="merchantInsuranceHistoryLoader || (!merchantInsuranceHistoryLoader && merchantInsuranceHistory.length > 0)" class="mt-4 p-5">
      <div class="flex mb-2 w-full justify-between items-center">
        <h2 class="title mb-6">{{$t('Insurance History')}}</h2>
      </div>
      <div v-for="(item, index) in merchantInsuranceHistory.slice(0, limit)" :key="index" class="md:ml-4">
        <div class="rounded-lg">
          <ul class="vx-timeline h-20 w-full mb-0 xs:mb-24 sm:mb-0">
            <li>
              <div class="timeline-icon h-2 w-2 inline-block rounded-full mr-2 bg-grey"></div>
              <div class="vx-row flex">
                <div class="vx-col w-1/3 sm:w-1/3 xs:w-full xs:mb-1">
                  <small class="activity-e-status block">{{$t("Date")}}</small>
                  <p class="font-medium lg:text-sm mr-2">{{ new Date(item.created).toLocaleDateString('fr-CA')}} {{ new Date(item.created).toLocaleTimeString() }}</p>
                </div>
                <div class="vx-col xl:pl-0 lg:w-1/3 lg:pr-2 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                  <small class="activity-e-time">{{$t("Insurance Package Name")}}</small>
                  <p class="font-medium lg:text-sm mr-2">{{item.insurance_package ? item.insurance_package.name + ` (${item.insurance_package.payout_coverage}% ${$t(' payout coverage')})` : '' }}</p>
                </div>
                <div class="vx-col xl:w-1/3 lg:w-1/4 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                  <small class="activity-e-time">{{$t("Effective Date")}}</small>
                  <p class="font-medium lg:text-sm">{{ item.effective_date ? item.effective_date : '' }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <button class="vs-button w-full" :class="limit === 2 ? 'vs-button-filled vs-button-primary' : 'button-wight'" @click="showMore()">{{ limit === 2 ? $t('view more') : ('view Less')}} <feather-icon :icon="limit === 2 ? 'ChevronDownIcon' : 'ChevronUpIcon'" svgClasses="h-4 w-4" class="align-middle"/></button>
    </shipblu-card>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      @cancel="closeModal"
      :active.sync="showModal"
      :title="$t('Are you Sure That You Want This Insurance Plan?')"
      :buttons-hidden="true" autocomplete="nofill">
      <p class="text-center font-normal leading-8 text-lg">
        <span class="font-semibold">{{insurancePackage.insurance_fees}}%</span> 
        {{$t(' Insurance Fees Per Order - ')}}
        <span class="font-semibold">{{insurancePackage.payout_coverage}}%</span>
        {{$t(' Payout Coverage')}}
      </p>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn cursor-pointer">{{ $t('Cancel') }}</button>
        <button @click="addInsurance()" class="active-btn btn cursor-pointer">{{ $t('Confirm') }}</button>
      </div>
    </shipblu-prompt>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      @cancel="closeModal"
      :active.sync="showDisableModal"
      :title="$t('Are you Sure That You Want To Disable This Insurance Plan?')"
      :buttons-hidden="true" autocomplete="nofill">
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="showDisableModal = false" class="btn disable-btn cursor-pointer">{{ $t('Cancel') }}</button>
        <button @click="addInsurance('empty')" class="active-btn btn cursor-pointer">{{ $t('Confirm') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>
<script>
import { sendRequest }  from '../../http/axios/requestHelper'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import common from '../../assets/utils/common'

export default {
  components: { ShipbluPrompt, ShipbluCard },
  data () {
    return {
      limit: 2,
      userMerchant: {},
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      trackEventsDic: {
        1 : 'ShipBlu_SHIELD_DISABLED',
        2 : 'ShipBlu_SHIELD_SUBMITTED_BASIC',
        3 : 'ShipBlu_SHIELD_SUBMITTED_STANDARD',
        4 : 'ShipBlu_SHIELD_SUBMITTED_PREMIUM'
      },
      value: '',
      insurancePackages: [],
      insurancePackage: {},
      isSelected: false,
      merchant: {},
      isActive: false,
      activeID: '',
      merchantInsurance: {
        insurance_package: {}
      },
      showModal: false,
      showDisableModal: false,
      merchantInsuranceHistory: [],
      merchantInsuranceLoader: false,
      merchantInsuranceHistoryLoader: false,
      merchantStatus: ''
    }
  },
  watch: {
    insurancePackage (val) {
      this.activeID = val.id
    }
  }, 
  methods: {
    showMore () {
      if (this.limit === 2) {
        this.limit = -1
      } else {
        this.limit = 2
      }
    },
    closeModal () {
      this.showModal = false
      this.showDisableModal = false
    },
    loadInsurancePlans () {
      sendRequest(true, false, this, 'api/v1/pricing/insurance-packages/', 'get', null, true,
        (response) => { 
          response.data.forEach(item => {
            if (item.id !== 1) {
              this.insurancePackages.push(item)
            }
          })
        }
      )
    },
    loadMerchantInsurance () {
      this.merchantInsuranceLoader = true
      sendRequest(true, false, this, `api/v1/merchants/${this.merchant.id}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.merchantInsurance = response.data
          this.merchantInsuranceLoader = false
        }
      )
    },
    MerchantInsuranceHistory () {
      this.merchantInsuranceHistoryLoader = true
      sendRequest(true, false, this, `api/v1/tracking/merchants/${this.merchant.id}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.merchantInsuranceHistory = response.data.results
          this.merchantInsuranceHistoryLoader = false
        }
      )

    },
    addInsurance (type) {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/insurance-packages/`, 'post', {insurance_package: type === 'empty' ? 1 : this.insurancePackage.id}, true,
        () => {
          if (this.releaseVersion !== 'staging') {
            this.$mixpanel.track(`${this.insurancePackage.id ? this.trackEventsDic[this.insurancePackage.id] : this.trackEventsDic[1]}`, {
              merchant_name: this.merchant.name,
              merchant_email: this.userMerchant.email,
              source: /(android)/i.test(navigator.userAgent)
            })
          }
          this.updateMerchant()
          this.insurancePackage = {}
          this.showModal = false
          this.showDisableModal = false
        }
      )
    },
    updateMerchant () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/`, 'patch', {default_order_declared_value: Number(this.value)}, true,
        () => {
          this.value = ''
          this.loadMerchantInsurance()
        }
      )
    },
    openModal () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.showModal = true
        }
      })
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.userMerchant = results.userMerchantData
      this.merchant = results.merchantData
      this.merchantStatus = this.merchant.status
      this.loadInsurancePlans()
      this.loadMerchantInsurance()
      this.MerchantInsuranceHistory()
    })
  }
}

</script>
<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";

.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
}
li {
  position: relative;
  .timeline-icon {
    position: absolute;
    top: 0;
    left: -3.2rem !important;
    border-radius: 50%;
    padding: 0px;
    padding: 0.3rem !important;
    padding-bottom: 0.4rem;
  }
}
input[type=checkbox].require-checked {
  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid red;
    border-radius: 3px;
  }
}
.title {
  color:#3169fc;
  background-color: transparent !important;
}
ul.list-type {
  list-style-type: disc;
}
.insurance-card{
  border: #D6D6D6 1px solid;
  width:298px;
  border-radius: 9px;
}
.active-insurance {
  border: #3169fc 1px solid;
  width:915px;
  border-radius: 9px;
}
.disabled-btn {
  background: #B7B7B7 !important;
  color: white;
  opacity: 1 !important;
  z-index: 1;
}
.error-box {
  background: rgba(210, 28, 28, 0.05);
}
.fake-radiobtn {
  border-radius: 50%;
  border: 1px solid rgba(210, 28, 28, 0.973);
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    border-radius: 50%;
    background: red;
    width: 11px;
    height: 11px;
  }
}
.button-wight{
  background: #fff;
  color: #B1B6BB;
  border: 1px solid #B1B6BB;
}
</style>
